import { FOCUS_STYLES } from 'src/constants';
import styled from 'styled-components';

export const ToastRegion = styled.div`
  position: fixed;
  z-index: 101; /* one more than that applied to full screen modals */
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &:focus-visible {
    ${FOCUS_STYLES}
  }
`;
